import errorImage from '../src/media/error.png';
function ErrorProbemePage(){
    return (

      <div class="error-page d-flex align-items-center justify-content-center">
      <div class="error-container text-center p-4">
          <h1 class="error-code mb-0" style={{color:"red"}}>Echec du paiement !</h1>
          <h2 class="display-6 error-message mb-3">Un problème est subvenu</h2>
          <p class="lead error-message mb-5">Veuillez ré-essayer plus tard.</p>
          <div class="d-flex justify-content-center gap-3">
              {/* <a href="#" class="btn btn-glass px-4 py-2">Retour </a> */}
              {/* <a href="#" class="btn btn-glass px-4 py-2">Report Problem</a> */}
          </div>
      </div>
  </div>
      
   //      <div class="container-fluid">
   //      <div class="row justify-content-center">
   //          <div class=" col-lg-6 col-md-8">
   //              <div class="card p-3">
   //                  <section id="main-content" class="container">
   //                      <div class="row-wrapper-x">
   //                         <section class="wpb_row  ">
   //                            <div class="wpb_column vc_column_container vc_col-sm-12">
   //                               <div class="vc_column-inner ">
   //                                  <div class="wpb_wrapper">
   //                                     <div  class="wpb_single_image wpb_content_element text-center">
   //                                      <img src={errorImage} style={{"width":"200px"}} alt="" />
   //                                       <h2 class="text-danger">Echec de l'opération</h2>
   //                                       <p>Un problème est subvenu, veuillez re-essayer plus tard</p>
   //                                     </div>
   //                                  </div>
   //                               </div>
   //                            </div>
   //                         </section>
   //                      </div>
   //                   </section>
   //              </div>
   //          </div>
   //      </div>
   //  </div>
    )
}

export default ErrorProbemePage;