function separateNumber(number) {
  if (number) {
    // Convertir le nombre en chaîne de caractères
    var numberString = number.toString();

    // Séparer les chiffres en centaines
    var separatedNumber = '';
    for (var i = numberString.length - 1; i >= 0; i--) {
      separatedNumber = numberString.charAt(i) + separatedNumber;
      if (i > 0 && (numberString.length - i) % 3 === 0) {
        separatedNumber = ' ' + separatedNumber;
      }
    }

    return separatedNumber;
  } else {
    return '0';
  }
}
export default separateNumber;