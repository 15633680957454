import axios from "axios";

import { useEffect, useState } from "react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Swal from "sweetalert2";
import Countdown from "./component/count_down";
import { useNavigate, useParams } from "react-router-dom";
import separateNumber from "./separateur";

// const url = "https://distripay-sanbox-api.distriforce.shop/api";
// const url = "https://b087-2c0f-ecf0-204-a500-61e1-4be1-9f89-6f37.ngrok-free.app/api";
// const url = "http://127.0.0.1:8000/api";
const url = "https://pay.distriforce.shop/api";
function Guichet_Page(){

    const navigate = useNavigate();
    // const params = new URLSearchParams(window.location.search);

    // const token = params.get('tken');
    // const code = params.get('c');
    // const amount = params.get('m');
    // const country = params.get('p');
    // const country = 'SN';
    // const countryLower = country.toLocaleLowerCase();


    // console.log(code);
    const {token} = useParams();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [valid, setValid] = useState(true);
    const [activeOperateur,setActiveOperateur] = useState('');
    const [otpTrue,setOtpTrue] = useState(false);
    const [loadingWithOtp,setLoadingWithOtp] = useState(false);
    const [loadingWithoutOtp,setLoadingWithoutOtp] = useState(false);
    const [otpCode,setOtpCode] = useState("");
    const [countryLower,setCountryLower] = useState("ci");
    const [phone, setPhone] = useState('');
    const [countryList,setCountryList] = useState([]);
    const [selectCountry,setSelectCountry] = useState("ci");
    const [urlPayment,setUrlPayment] = useState('');
    const [amount,setAmount] = useState(0);
    const [code,setCode] = useState('');
    const [returnUrl,setReturnUrl] = useState('');

    const [loading,setLoading] = useState(false);

    useEffect(()=>{
      fetchCountrieList();
      // get_country_code_function();
      getTransactionOnefunction();
    },[]);

    // const [country, setCountry] = useState('ci');

    // navigate(`/search/top?q=${poste.slug}`,{state:{search:searchValue}})

  // const get_country_code_function=()=>{
  //   setLoading(true);
  //   navigator.geolocation.getCurrentPosition((position) => {
  //     const { latitude, longitude } = position.coords;
      
  //     fetch(`https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=32487d74e2d142cf82fec955b3b65341`)
  //         .then(response => response.json())
  //         .then(data => {setLoading(false);
  //             const countryCode = data.results[0].components.country_code.toLocaleLowerCase();
  //             console.log(countryCode);
  //             setCountryLower(countryCode); // Met à jour le code du pays
  //             setSelectCountry(countryCode);
  //         })
  //         .catch(error => console.error('Error:', error));
  // });
  // }




    const getTransactionOnefunction=()=>{
      // setLoading(true);
      try {
        axios.get(url+'/transaction/'+token,{
            headers:{
                'Content-Type':'application/json',
                
            },
        }).then((resp)=>{
          // setLoading(false);
          if(resp.data.failed == false)
          {
            if(resp.data.data?.status_payment == "REFUSED" || resp.data.data?.status_payment == "ACCEPTED")
            {
              token = "";
              navigate('/transaction/failed-session-expired');
            }
            setCountryLower(resp.data.data?.customer_pays.toLocaleLowerCase());
            setSelectCountry(resp.data.data?.customer_pays.toLocaleLowerCase());
            setAmount(resp.data.data?.montant_initial);
            setCode(resp.data.data?.transaction_id);
            setReturnUrl(resp.data.data?.return_url);
          }
          else{
            // console.log("error :"+resp.data.failed);
            // navigate(`/transaction/failed-session-expired`,{state:{url:resp.data.data?.return_url}});
            navigate('/transaction/failed-session-expired');
          }

        }).catch((error)=>{
          console.log(error);
          navigate('/transaction/failed-transaction');
        })
      } catch (error) {
          
      }
    }

    const fetchCountrieList = async ()=>{
      try {
        axios.get(url+'/countrie/list',{
            headers:{
                'Content-Type':'application/json',
                
            },
        }).then((resp)=>{

            if(resp.status == 200)
            {
              setCountryList(resp.data.datas);
            }
            else
            {
                console.log(resp.data.status);   
            }
        })
      } catch (error) {
          console.log(error);
      }
    }
    const handleChange = (value,data) => {  
      var telNumber = value.replace(/[^0-9]+/g,'').slice(data.dialCode.length);   
      setCountryLower(data.countryCode);
      setPhoneNumber(value);
      setValid(validatePhoneNumber(value));

      setPhone(telNumber);
      if(telNumber.slice(0,2) == "05"){
        setActiveOperateur("mtn")
      }else if(telNumber.slice(0,2) == "01"){
        setActiveOperateur("moov")
      }else {
        setActiveOperateur("orange");
      }
    };
  
    const validatePhoneNumber = (phoneNumber) => {
      const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;
  
      return phoneNumberPattern.test(phoneNumber);
    };

    const nextOtpSubmit=()=>{

        if(phoneNumber.length >= 9){
            setOtpTrue(true);
        }else{
            Swal.fire({
                title: 'Désolé',
                text: 'Le numéro saisi est incorrect.',
                confirmButtonText:"OK,compris!",
                confirmButtonColor:"red"
              })
        }
    }

    const initiatPaymentwithOtpFunction=(e)=>{
        e.preventDefault();

        const _formData = new FormData();

// console.log(phone);
        if(activeOperateur !==""){
            _formData.append('operator',activeOperateur);
            _formData.append('amount',amount);
            _formData.append('mobile',phone);
            _formData.append('otp',otpCode);
            _formData.append('transactionId',code);
            _formData.append('countryCode',activeOperateur !== "wave" ? countryLower : selectCountry );
            setLoadingWithOtp(true);
            setLoadingWithoutOtp(true);
            var i=0;
            try {
                axios.post(url+'/init-payment',_formData,
                {
                    headers:{
                        'Content-Type':'application/json',
                        
                    },
                    // credentials:'include'
                }
                ).then((resp)=>{

                  // console.log(resp.data.checkoutId);
                    if(resp.status === 200){
                       

                      const intervalId = setInterval(()=>{
        
                        const _formData = new FormData();
                        _formData.append('transaction_id',code);
                        _formData.append('checkoutId',resp.data.checkoutId);
                        var checkouId = resp.data.checkoutId;
                    
                        try {

                            axios.post(url+'/check-payment',_formData,
                            {
                                headers:{
                                    'Content-Type':'application/json',
                                    
                                },
                                // credentials:'include'
                            }
                            ).then((resp)=>{
                              // console.log(resp.data.data.paymentStatus);
                              
                              if(resp.data.data?.operator?.mnoName !== "WAVE"){

                                    if(resp.data.data?.paymentStatus == "SUCCEEDED"){

                                      setLoadingWithOtp(false);
                                      setLoadingWithoutOtp(false);
                                      // navigate(`/transaction/failed-session-expired`,{state:{url:resp.data.data?.return_url}})
                                      navigate(`/success/${checkouId}`,{state:{url:returnUrl}});
                                      window.location.reload();
                              
              
                                    }else if(resp.data.data?.paymentStatus == "FAILED"){
                                      setLoadingWithOtp(false);
                                      setLoadingWithoutOtp(false);
                                      navigate(`/failed/${checkouId}`,{state:{url:returnUrl}});
                                      // navigate('/failed/'+checkouId);
                                      window.location.reload();
              
                                    }else{
                                      // console.log("Lou masque sur toi DS:");
                                        // setLoadingWithOtp(false);
                                        // console.log(resp.data.data);
                                    }
                              }else{

                                if(resp.data.data?.paymentStatus == "SUCCEEDED"){

                                  setLoadingWithOtp(false);
                                  setLoadingWithoutOtp(false);
                                  navigate(`/success/${checkouId}`,{state:{url:returnUrl}});
                                  // navigate('/success/'+checkouId);
                                  window.location.reload();
                          
          
                                }else if(resp.data.data?.paymentStatus == "FAILED"){
                                  setLoadingWithOtp(false);
                                  setLoadingWithoutOtp(false);
                                  // navigate('/failed/'+checkouId);
                                  navigate(`/failed/${checkouId}`,{state:{url:returnUrl}});
                                  window.location.reload();
          
                                }else{
                                  if(i == 0){
                                  console.log(i)
                                  i = 1;
                                  setUrlPayment(resp.data.data?.paymentUrl)
                                  // // setLunchWave(2);
                                    // window.open(resp.data.data?.paymentUrl, '_blank') ;
                                  }
                                   
                                }
                              }

                            })
                        } catch (error) {
                          // setLoadingWithOtp(false);
                          console.log(error);  
                        }
                      },5000);
        
                      if(activeOperateur == "moov" || activeOperateur == "mtn"){
                            setTimeout(() => {
                              clearInterval(intervalId);
                              navigate(`/waiting-transaction/${token}`,{state:{url:returnUrl}});
                              // navigate('/failed/'+token);
                            },5 * 60 * 1000);
                      }else{
                          setTimeout(() => {
                            clearInterval(intervalId);
                            navigate(`/waiting-transaction/${token}`,{state:{url:returnUrl}});
                            // navigate('/failed/'+token);
                          },5 * 60 * 1000);
                      }
                     
        
        
                    }else{
                      // console.log("Lou masque sur toi :3232");
                      setLoadingWithOtp(false);
                    }

                }).catch((error)=>{  
                  setLoadingWithOtp(false);
                  setLoadingWithoutOtp(false);
                  console.log(error.response);              
                    Swal.fire({
                      title: 'Désolé',
                      text: 'Une erreur est survenue.',
                      confirmButtonText:"OK,compris!",
                      confirmButtonColor:"red"
                    })
                    // console.log(errors);
                })
            } catch (error) {
                setLoadingWithOtp(false);
                setLoadingWithoutOtp(false);
                // console.log("Lou masque sur toi 1 :");
            }

        }else{

            // if(phoneNumber.length != 10){
            //     Swal.fire({
            //         title: 'Désolé',
            //         text: 'Le numéro saisi est incorrect.',
            //         confirmButtonText:"OK,compris!",
            //         confirmButtonColor:"red"
            //       })
            // }

            if(activeOperateur == ""){
                Swal.fire({
                    title: 'Désolé',
                    text: "L'opérateur n'a pas été choisi",
                    confirmButtonText:"OK,compris!",
                    confirmButtonColor:"red"
                  })
            }
        }
    }


  
    return (
      <>
      {urlPayment != "" &&
        <div>
            <iframe src={urlPayment} width="100%" height="1000" allow="payment; fullscreen"></iframe>
        </div>
            
      }
      {urlPayment == "" &&
         <>
         
   
        <div class="container-fluid error-page ">
          <div class="row justify-content-center">
              <div class=" col-lg-6 col-md-8">
                {loading && 
                 <div class="card p-3">
                  <div class="d-flex justify-content-center p-4 mt-3">
                      <div class="spinner-grow" style={{"width": "3rem", "height": "3rem"}} role="status">
                      <span class="visually-hidden"></span>
                    </div>
                    <div class="spinner-grow" style={{"width": "3rem", "height": "3rem"}} role="status">
                      <span class="visually-hidden"></span>
                    </div>
                    <div class="spinner-grow" style={{"width": "3rem", "height": "3rem"}} role="status">
                      <span class="visually-hidden"></span>
                    </div>
                </div>
                </div>
                }
                 {!loading && 
                  <div class="card p-3">
                      <div class="row justify-content-between">
                          <img src="assets/images/logo-distripay.png" alt="" class="w-25 ml-4" />
                          <h6 class="mt-1 mr-4"style={{"font-family": "century gothic", "font-size": "20","font-weight": "bold"}}>{separateNumber(amount)??0} XOF</h6>
                      </div>
                      <div class="pt-4 pl-2 pr-2 pb-2" style={{"margin-top": "5%", "background-color": "#bdc3c7"}}>
                          <ul role="tablist" class="nav bg-white nav-pills rounded nav-fill mb-3">
                              <li class="nav-item" style={{"font-family": "century gothic", "font-size": "5"}}> <a data-toggle="pill" href="#mobile-money" class="nav-link active "> <i class="fas fa-mobile-alt mr-2"></i> Mobile Money </a> </li>
                              <li class="nav-item" style={{"font-family": "century gothic", "font-size": "5"}}> <a data-toggle="pill" href="#wallet" class="nav-link "> <i class="fas fa-credit-card mr-2"></i> Wallet </a> </li>
                              {/* <!-- <li class="nav-item"> <a data-toggle="pill" href="#paypal" class="nav-link "> <i class="fab fa-paypal mr-2"></i> Paypal </a> </li> --> */}

                          </ul>
                      </div>
                      <div class="tab-content">
                            <div id="mobile-money" class="tab-pane fade show active pt-0">
                                  {!otpTrue &&<form onSubmit={initiatPaymentwithOtpFunction} class="form-card">
                                      <div class="row justify-content-center">
                                      {/* <div class="col-md-3"></div> */}
                                          <div class="col-md-12">
                                              <label for="exampleInputEmail1" style={{"font-family": "century gothic", "font-size": "5"}}> Numéro de téléphone :</label>
                                              <PhoneInput                                                  
                                                  country={countryLower}
                                                  onlyCountries={['ci','sn']}
                                                  placeholder="07 68 12 13 40"
                                                  value={phoneNumber}
                                                  onChange={handleChange}
                                                  inputProps={{
                                                      required: true,
                                                  }}
                                                  masks={{ci: '.. .. .. .. ..',sn:'... ... ...'}}
                                                  inputClass="form-control w-100"
                                                  containerStyle={{"width":"100%"}}
                                                  />
                                          
                                          </div>
                                          {/* <div class="col-md-3"></div> */}
                                      </div>
                                      <div class="row mb-4 radio-group d-flex justify-content-between flex-nowrap" style={{"margin-top": "20px"}}>
                                            {countryLower !== null &&
                                            <div class="col-4  d-flex justify-content-center">
                                                  <div class={activeOperateur == "orange" ?'radio selected ' : "radio "}> 
                                                      <img class="fit-image rounded " src="assets/images/orange-money-logo.webp" width="105px" height="55px" /> 
                                                  </div>
                                              </div>
                                              }
                                                  {countryLower == 'ci' &&
                                                      <>
                                                          <div class="col-4  d-flex justify-content-center">
                                                            <div class={activeOperateur == "mtn" ?'radio selected ' : "radio "} > 
                                                                <img class="fit-image rounded" src="assets/images/MTN_lance_le_premier_chatbot_pour_son_service_de_mobile_money.jpg" width="105px" height="55px" />
                                                            </div>
                                                          </div>
                                                          <div class="col-4  d-flex justify-content-center">
                                                          <div class={activeOperateur == "moov" ?'radio selected ' : "radio "} >
                                                              <img class="fit-image rounded " src="assets/images/Moov_Africa_logo.png" width="105px" height="55px" /> 
                                                          </div>
                                                          </div>
                                                      </>
                                                  }
                                              
                                          
                                        
                                      </div>
                                      <div class="row justify-content-center">
                                        {loadingWithOtp &&  
                                                  <button type="submit" class="btn btn-pay placeicon w-100" style={{"font-family": "century gothic", "font-size": "12"}}  disabled> 
                                                        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                        En attente de confirmation (<Countdown />)...
                                                  </button> 
                                            }
                                          {!loadingWithOtp && activeOperateur == "" && <button type="submit" class={amount > 0 ? "btn btn-pay placeicon w-100" : "btn btn-pay placeicon w-100 disabled"} style={{"font-family": "century gothic", "font-size": "12"}} >Payer {amount??0} XOF</button>}
                                          {!loadingWithOtp && activeOperateur == "mtn" && <button type="submit" class={amount > 0 ? "btn btn-pay placeicon w-100" : "btn btn-pay placeicon w-100 disabled"} style={{"font-family": "century gothic", "font-size": "12"}} >Payer {amount??0} XOF</button>}
                                          {!loadingWithOtp &&  activeOperateur == "moov" && <button type="submit" class={amount > 0 ? "btn btn-pay placeicon w-100" : "btn btn-pay placeicon w-100 disabled"} style={{"font-family": "century gothic", "font-size": "12"}} >Payer {amount??0} XOF</button> }
                                          {activeOperateur == "orange" && <button type="button" class="btn btn-pay placeicon w-100" onClick={()=>nextOtpSubmit()}>Obtenir un code</button> }
                                          
                                          
                                      </div>
                                  </form>}

                                  {otpTrue &&
                                      <form class="card" onSubmit={initiatPaymentwithOtpFunction}>
                                          <div class="card-body">
                                              <h5 class="card-title text-center" style={{"font-family": "century gothic", "font-size": "12","font-weight": "bold"}}>Confirmation du paiement</h5>
                                              <div className="bg-light p-3">
                                                  <span style={{"font-family": "century gothic", "font-size": "8"}}>1. Pour générer un code temporaire. Composez le <b>#144*82#</b> et lancez</span><br />
                                                  <span style={{"font-family": "century gothic", "font-size": "8"}}>2. Tapez votre code secret et validez</span><br />
                                                  <span style={{"font-family": "century gothic", "font-size": "8"}}>3. Veuillez copier et coller le code reçu par SMS dans le champs ci-dessous.</span>
                                              </div>

                                              <div class="form-group mt-2 mb-3">
                                                  <h5 for="exampleInputEmail1">Saisissez le code reçu par SMS</h5>
                                                  <input type="text" class="form-control" id="exampleInputEmail1" minLength={4} maxLength={4} placeholder="Ex: 7630" onChange={(e)=>setOtpCode(e.target.value)} required/>
                                              </div>

                                              {!loadingWithoutOtp && <button type="button" class="btn  placeicon w-30 btn-danger mt-4 p-3" style={{"font-family": "century gothic", "font-size": "12"}}  onClick={()=>setOtpTrue(false)}> <i class="fa-solid fa-chevron-left"></i> Retour </button> }
                                              {!loadingWithoutOtp && <button type="submit" class="btn btn-pay  placeicon w-50 btn-primary float-right mt-4" style={{"font-family": "century gothic", "font-size": "12"}} > Payer {amount??0} XOF </button> }
                                              {loadingWithoutOtp  &&  <button type="submit" class="btn  placeicon w-100 btn-primary float-right mt-4 p-3" style={{"font-family": "century gothic", "font-size": "12"}}  disabled> 
                                                  <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                    En attente de confirmation...
                                              </button> }

                                          </div>
                                    </form>
                                  }
                            </div>
                            <form id="wallet" class="tab-pane fade pt-3 form-card" onSubmit={initiatPaymentwithOtpFunction}>
                              <div class="form-group"> 
                                  <label for="Select Your Bank">
                                      <h6 style={{"font-family": "century gothic", "font-size": "12"}}>Sélectionnez votre pays</h6>
                                  </label> 
                                  <select class="form-control" id="root" onChange={(e)=>{setSelectCountry(e.target.value); setActiveOperateur('')}} value={selectCountry}>
                                  <option value="" style={{"font-family": "century gothic", "font-size": "12"}} selected>Choisissez votre Wallet</option>
                                    {countryList?.map((countrie,index)=>
                                        <option value={countrie.countryCode} style={{"font-family": "century gothic", "font-size": "12"}} key={index}>{countrie.libelle}</option>
                                    )}
                                      {/* <option value="senegal" style={{"font-family": "century gothic", "font-size": "12"}}>Sénégal</option> */}
                                  </select> 
                              </div>
                                {selectCountry  == 'ci' &&
                                <>
                                      {/* <b className="text-danger">Wallet indisponible en Côte d'Ivoire</b> */}
                                      <div class="col-sm-4" id="ciImage"  onClick={()=>setActiveOperateur('wave')}>
                                          <div class={activeOperateur =='wave' ? 'radio mx-auto selected' : 'radio mx-auto'} data-value="waveCi">
                                              <img class="fit-image" src="assets/images/wave-mobile-money.webp" width="105px" height="55px" /> 
                                              <div style={{"font-family": "century gothic", "font-size": "12"}}>Côte d'Ivoire</div>
                                          </div>
                                      </div>
                                
                                </>
                                 
                                }
                                {selectCountry  == 'sn' &&
                                    <div class="col-sm-4" id="senegalImage" onClick={()=>setActiveOperateur('wave')}>
                                        <div class={activeOperateur =='wave' ? 'radio mx-auto selected' : 'radio mx-auto'} data-value="waveCi">
                                            <img class="fit-image" src="assets/images/wave-mobile-money.webp" width="105px" height="55px" /> 
                                            <div style={{"font-family": "century gothic", "font-size": "12"}}>Sénégal</div>
                                        </div>
                                    </div>
                                }
                              
                              {/* <!-- <p id="senegalText" class="text-muted" style="display: none;">Note: After clicking on the button, you will be directed to a secure gateway for payment. After completing the payment process, you will be redirected back to the website to view details of your order. </p> --> */}
                              <div class="row justify-content-center">
                                      {loadingWithOtp &&  
                                              <button type="submit" class="btn btn-pay placeicon w-100" style={{"font-family": "century gothic", "font-size": "12"}}  disabled> 
                                                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                    En attente de confirmation
                                              </button> 
                                        }
                                      {!loadingWithOtp && <button type="submit" class={amount > 0 ? "btn btn-pay placeicon w-100" : "btn btn-pay placeicon w-100 disabled"} style={{"font-family": "century gothic", "font-size": "12"}} >Payer {amount??0} XOF</button>}
                              </div>
                          </form>
                      </div>
                  </div>
                   }
              </div>
          </div>
        </div> 
        
        </> }

    
        
      </>


    )
}

export default Guichet_Page;